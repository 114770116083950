import * as httpCall from "../../../../../Utils/apis/apis"

export const getDefaultuserList = async (defaultEmail, crmId) => {
  let url = `/GenericGremlin/generic/getDefaultDealDetails`;
  let requestPayload = {
    "@id": crmId,
    "@defaultUserMail": defaultEmail
  };
  try {
    const result = await httpCall.httpPost(url, requestPayload);
    if (!result || result.error) {
      throw new Error(result?.error || 'Error in API response');
    }
    return result;
  } catch (error) {
    console.error('Error calling getDefaultuserList API:', error);
  }
};
